import * as types from '../types/app'

export const updateDrawerNavigationMenuState = (value) => ({
  type: types.UPDATE_DRAWER_NAVIGATION_MENU_STATE,
  value,
})

export const updateTheme = (value) => ({
  type: types.UPDATE_THEME,
  value,
})

export const initializeServiceWorker = () => ({
  type: types.INITIALIZE_SERVICE_WORKER,
})

export const updateServiceWorker = (payload) => ({
  type: types.UPDATE_SERVICE_WORKER,
  payload,
})
