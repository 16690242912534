export const UPDATE_QUESTION_MODAL_FORM_DATA_SUCCESS =
  'UPDATE_QUESTION_MODAL_FORM_DATA_SUCCESS'
export const UPDATE_GAME_SET_DATA_SUCCESS = 'UPDATE_GAME_SET_DATA_SUCCESS'

export const UPDATE_GAME_LIST_DATA_SUCCESS = 'UPDATE_GAME_LIST_DATA_SUCCESS'
export const FETCH_QUESTION_REPLACE_LIST = 'FETCH_QUESTION_REPLACE_LIST'

export const FETCH_QUESTION_REPLACE_LIST_SUCCESS =
  'FETCH_QUESTION_REPLACE_LIST_SUCCESS'

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS'
export const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED'

export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'

export const FETCH_CHAPTERS = 'FETCH_CHAPTERS'
export const FETCH_CHAPTERS_SUCCESS = 'FETCH_CHAPTERS_SUCCESS'
export const FETCH_CHAPTERS_FAILED = 'FETCH_CHAPTERS_FAILED'

export const FETCH_FILTERS_FOR_GAME = 'FETCH_FILTERS_FOR_GAME'
export const FETCH_FILTERS_FOR_GAME_SUCCESS = 'FETCH_FILTERS_FOR_GAME_SUCCESS'
export const FETCH_RANDOM_GAME_FAILURE = 'FETCH_RANDOM_GAME_FAILURE'

export const FETCH_RANDOM_GAME = 'FETCH_RANDOM_GAME'
export const FETCH_RANDOM_GAME_SUCCESS = 'FETCH_RANDOM_GAME_SUCCESS'

export const SET_BOARD = 'SET_BOARD'
export const SET_CLASS = 'SET_CLASS'
export const SET_SUBJECTS = 'SET_SUBJECTS'
export const SET_CHAPTERS = 'SET_CHAPTERS'

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT'
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS'

export const FETCH_RANDOM_QUESTION = 'FETCH_RANDOM_QUESTION'
export const FETCH_RANDOM_QUESTION_SUCCESS = 'FETCH_RANDOM_QUESTION_SUCCESS'

export const SET_SELECTED_QUESTION_INDEX = 'SET_SELECTED_QUESTION_INDEX'

export const GET_GAME_LIST = 'GET_GAME_LIST'
export const GET_GAME_LIST_SUCCESS = 'GET_GAME_LIST_SUCCESS'

export const SET_HOME_PAGE_VIEW = 'SET_HOME_PAGE_VIEW'
export const UPDATE_COLLECTIONS_FILTER_SUCCESS =
  'UPDATE_COLLECTIONS_FILTER_SUCCESS'

export const SET_NOTIFY_TOAST = 'SET_NOTIFY_TOAST'
