export default {
  SUCCESS: 'SUCCESS',
  ACCESS_TOKEN_KEY: 'OPENHOUSE-ACCESS-TOKEN-KEY',
  LAST_UPDATED_PERMISSIONS_DATE: 'OPENHOUSE-PERMISSIONS-LAST-UPDATED-ON',
  PERMISSIONS_KEY: 'OPENHOUSE-PERMISSIONS-KEY',
  DARK: 'DARK',
  ERROR: 'ERROR',
  ERROR_MESSAGE: 'Something Went Wrong!',
  LIGHT: 'LIGHT',
  NETWORK_ERROR: 'Network Error',
  PAGE_NO: 'PAGE_NO',
  REFRESH_TOKEN_KEY: 'OPENHOUSE-REFRESH-TOKEN-KEY',
  REFRESH_TOKEN_LAST_UPDATED: 'OPENHOUSE-REFRESH-TOKEN-LAST-UPDATED',
}

export const questionTypes = {
  multiple_reasoning_questions: 'multiple_reasoning_questions',
  mcq: 'mcq',
  true_and_false: 'true_and_false',
  fill_in_the_blanks: 'fill_in_the_blanks',
  one_word: 'one_word',
  descriptive: 'descriptive',
}

export const selectTypes = [
  {
    type: questionTypes.multiple_reasoning_questions,
    label: 'Multiple Reasoning',
  },
  {
    type: questionTypes.mcq,
    label: 'Multiple Choice',
  },
  {
    type: questionTypes.true_and_false,
    label: 'True or False',
  },
  {
    type: questionTypes.fill_in_the_blanks,
    label: 'Fill in the blanks',
  },
  {
    type: questionTypes.one_word,
    label: 'Short answer',
  },
  {
    type: questionTypes.descriptive,
    label: 'Long answer',
  },
]

export const difficultyTypes = [
  {
    type: 'easy',
    label: 'Easy',
  },
  {
    type: 'medium',
    label: 'Medium',
  },
  {
    type: 'hard',
    label: 'Hard',
  },
]

export const boards = [
  {
    label: 'ICSE',
    value: 'ICSE',
  },
  {
    label: 'ISC',
    value: 'ISC',
  },
  {
    label: 'CBSE',
    value: 'CBSE',
  },
]

export const question_set_type = [
  {
    label: 'Quiz',
    value: 'quiz',
  },
  {
    label: 'Jeopardy',
    value: 'jeopardy',
  },
]

export const rating = [
  {
    label: '<img src="/icons/fourStarRating.svg"/>',
    value: 'gte__4',
  },
  {
    label: '<img src="/icons/threeStarRating.svg"/>',
    value: 'gte__3',
  },
  {
    label: '<img src="/icons/twoStarRating.svg"/>',
    value: 'gte__2',
  },
  {
    label: '<img src="/icons/oneStarRating.svg"/>',
    value: 'gte__1',
  },
]

export const colorArray = [
  '#EF5C34',
  '#6585B6',
  '#4293AC',
  '#DA9206',
  '#58C29C',
]

export const columnColorArray = [
  { background: '#FFFDE7', hover: '#FFF9C4', stroke: '#FDD835' },
  { background: '#FBE9E7', hover: '#FFCCBC', stroke: '#F4511E' },
  { background: '#E8F5E9', hover: '#C8E6C9', stroke: '#43A047' },
  { background: '#E1F5FE', hover: '#B3E5FC', stroke: '#039BE5' },
  { background: '#E8EAF6', hover: '#C5CAE9', stroke: '#3949AB' },
  { background: '#FFEBEE', hover: '#F8BBD0', stroke: '#D81B60' },
]

export const collectionsTimeFilter = [
  {
    label: `5 - 10 mins`,
    value: `gte__300,lte__600`,
  },
  {
    label: `10 -20 mins`,
    value: `gte__600,lte__1200`,
  },
  {
    label: `20 - 30 mins`,
    value: `gte__1200,lte__1800`,
  },
  {
    label: `30 - 45 mins`,
    value: `gte__1800,lte__2700`,
  },
  {
    label: `45 - 60 mins`,
    value: `gte__2700,lte__3600`,
  },
  {
    label: `> 60 minutes`,
    value: `gt__3600`,
  },
]

export const questionsTimeFilter = [
  {
    label: `<= 30 seconds`,
    value: `lte__30`,
  },
  {
    label: `<= 1 minute`,
    value: `lte__60`,
  },
  {
    label: `<= 2 minutes`,
    value: `lte__120`,
  },
  {
    label: `<= 3 minutes`,
    value: `lte__180`,
  },
  {
    label: `<= 5 minutes`,
    value: `lte__300`,
  },
  {
    label: `> 5 minutes`,
    value: `gt__300`,
  },
]
