import { listenerCount } from 'events'
import qs from 'querystring'

import { Toast } from '@openhouse-engineers/rrc'
import dayjs from 'dayjs'
import EventEmitter from 'eventemitter3'
import { isEmpty, isUndefined } from 'lodash'
import React from 'react'
import { store as notificationStore } from 'react-notifications-component'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

import { getNewAccessToken as getNewAccessTokenApi } from 'api'
import { fetchPermissions } from 'api/permissions'
import constants from 'app/utils/constants'
import { decodeJWT, isTokenExpired } from 'app/utils/utils'

const _eventEmitter = new EventEmitter()
export const eventEmitter = {
  addListener: (event, func) => _eventEmitter.addListener(event, func),
  removeListener: (event, func) => _eventEmitter.removeListener(event, func),
  emit: (event, data) => _eventEmitter.emit(event, data),
}

// freezing eventEmitter, so that later it won't get modified by any chance
Object.freeze(eventEmitter)

export const cookies = new Cookies()

const defaultCookieOption = {
  path: '/',
  secure: process.env.NODE_ENV !== 'development', // not secure only in development env
  maxAge: 30 * 86400, // 30 days
}

const subdomainCookieOption = {
  sameSite: 'lax',
  ...(process.env.NODE_ENV !== 'development'
    ? { domain: '.openhouse.study' }
    : null),
}

const cookieOption = {
  ...defaultCookieOption,
  ...subdomainCookieOption,
}

export const getCookie = (key) => {
  return cookies.get(key)
}

export const setCookie = (key, value) => {
  return cookies.set(key, value, cookieOption)
}

export const removeCookie = (key) => {
  cookies.remove(key, cookieOption)
  /**
   * try to remove cookie with defaultCookieOption as fallback
   * bcz remove config should be same as create config
   */
  cookies.remove(key, defaultCookieOption)
}

export const refreshAllCookies = () => {
  const allCookies = cookies.getAll()
  console.log('refreshing all cookies :- ', allCookies)

  for (const [cookieName, cookieValue] of Object.entries(allCookies)) {
    removeCookie(cookieName)
    setCookie(cookieName, cookieValue)
  }
}

export const getAccessToken = () => {
  const token = getCookie(constants.ACCESS_TOKEN_KEY)

  if (token && !isTokenExpired(token)) {
    return token
  }
}

export const setAccessToken = (value, name) => {
  setCookie(name, value)
}

export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

export const clearStorage = () => {
  try {
    const keys = [
      constants.ACCESS_TOKEN_KEY,
      constants.REFRESH_TOKEN_KEY,
      constants.REFRESH_TOKEN_LAST_UPDATED,
    ]

    keys.forEach((key) => removeCookie(key))
    localStorage.removeItem(constants.PERMISSIONS_KEY)
    localStorage.removeItem(constants.LAST_UPDATED_PERMISSIONS_DATE)
  } catch (error) {
    console.log(error)
  }
}

export const getNewAccessToken = async () => {
  const accessToken = getAccessToken()
  const refreshToken = getCookie(constants.REFRESH_TOKEN_KEY)
  const refreshTokenLastUpdated = getCookie(
    constants.REFRESH_TOKEN_LAST_UPDATED
  )

  if (accessToken && refreshToken && refreshTokenLastUpdated) {
    // calculate difference in days
    const lastUpdatedAgoInDays = new Dayjs().diff(
      new Dayjs(refreshTokenLastUpdated),
      'days'
    )

    // fetch new token if its older than 25 days
    if (lastUpdatedAgoInDays > 25) {
      try {
        const response = await getNewAccessTokenApi({
          refresh_token: refreshToken,
        })

        setAccessToken(response.access_token, constants.ACCESS_TOKEN_KEY)
        setAccessToken(response.refresh_token, constants.REFRESH_TOKEN_KEY)
        setCookie(
          constants.REFRESH_TOKEN_LAST_UPDATED,
          new Dayjs().format('YYYY/MM/DD HH:mm:ss')
        )

        return response.access_token
      } catch (error) {
        console.log('error in getting new access token :- ', error.message)
        throw error
      }
    } else {
      return accessToken
    }
  }
}

export const isUserAuthenticated = () => {
  const accessToken = getCookie(constants.ACCESS_TOKEN_KEY)

  if (accessToken) {
    return isTokenExpired(accessToken) === false
  }
}

export const getLoggedInUserId = () => {
  try {
    const accessToken = getAccessToken()

    if (accessToken && !isTokenExpired(accessToken)) {
      const user = decodeJWT(accessToken)
      return user.sub
    }
  } catch (error) {
    console.log('error in getting logged in user id :- ', error)
  }
}

export const getLoggedInUserName = () => {
  try {
    const accessToken = getAccessToken()

    if (accessToken && !isTokenExpired(accessToken)) {
      const user = decodeJWT(accessToken)
      return user.name
    }
  } catch (error) {
    console.log('error in getting logged in user name :- ', error)
  }
}

export const checkIfNetworkError = (error) => {
  return (
    error.isAxiosError &&
    isUndefined(error.response) &&
    error.message === constants.NETWORK_ERROR
  )
}

export const getNetworkResponseError = (error) => {
  const defaultError = {
    isError: true,
    fieldErrors: {},
    errorMessage: '',
  }

  try {
    if (checkIfNetworkError(error)) {
      return {
        ...defaultError,
        errorMessage: constants.NETWORK_ERROR,
      }
    } else {
      if (error.response.data.error) {
        const errorMessage =
          error.response.data.errorMessage || error.response.data.message

        if (!isEmpty(errorMessage)) {
          return {
            ...defaultError,
            errorMessage,
          }
        }
      } else if (!isEmpty(error.response.data)) {
        const fieldErrors = {}

        for (const [key, value] of Object.entries(error.response.data)) {
          fieldErrors[key] = Array.isArray(value) ? value.join('\n') : value
        }

        return {
          ...defaultError,
          errorMessage: '',
          fieldErrors,
        }
      }
    }

    return defaultError
  } catch (error) {
    // returning defaultError in case of any exception above
    return defaultError
  }
}

export const apiFormattedResult = (data) => {
  if (data) {
    return {
      count: data.count,
      data: data.results,
      previous: data.previous,
      next: data.next,
      ...(data.label ? { label: data.label } : null),
    }
  }
}

const SwalToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const showToast = (message, type) => {
  if (type === constants.SUCCESS) {
    SwalToast.fire({
      icon: 'success',
      title: message,
    })
  } else if (type === constants.ERROR) {
    SwalToast.fire({
      icon: 'error',
      title: message,
    })
  }
}
/**
 *
 * @param {*} successFunc which will be called if yes is pressed
 * @param {*} message the confirm message
 */
export const confirm = (
  successFunc,
  message = "You won't be able to revert this!"
) => {
  Swal.fire({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
  }).then((result) => {
    if (result.isConfirmed) {
      successFunc()
    }
  })
}

export const eventAlert = (message, type, title) => {
  if (type === constants.SUCCESS) {
    Swal.fire(title || 'Success', message, type)
  } else if (type === constants.ERROR) {
    Swal.fire(title || 'Error', message, type)
  }
}

export const makeRequestUrl = (url, queries) => {
  if (!isEmpty(url) && !isEmpty(queries)) {
    const formattedQueries = {}
    Object.keys(queries).forEach((key) => {
      let queryPrefix = key
      if (queryPrefix.indexOf('.') > -1) {
        queryPrefix = queryPrefix.replace(/\./g, '__')
      }

      if (queries[key] !== undefined) {
        formattedQueries[queryPrefix] = queries[key]
      }
    })
    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${qs.stringify(
      formattedQueries
    )}`
  }
  return url
}

// below helpers taken from oh admin dashboard for login, modify later if needed
/**
 * Checks if the passed variable is empty or not.
 * Works with object and array also
 * @param {*} params
 * @param {string} condition either of ('and', 'or', 'all', 'any')
 * @returns bool
 * Examples:
 * var false_value = 0/null/false/undefined/''
 * -> isNullOrEmpty(a) // true
 * var name_phone_empty = {name: '',  phone: ''}
 * -> isNullOrEmpty(name_phone_empty, 'all') // true
 * -> isNullOrEmpty(name_phone_empty, 'any') // true
 * var name_empty = {name: '', phone: '99876473934'}
 * -> isNullOrEmpty(name_empty, 'all') // false
 * -> isNullOrEmpty(name_empty, 'any') // true
 * var no_empty = {name: 'John', phone: '9876656748'}
 * -> isNullOrEmpty(no_empty, 'all') // false
 * -> isNullOrEmpty(no_empty, 'any') // false
 * var empty_elements_array = [null, false, 0, undefined, '']
 * -> isNullOrEmpty(empty_elements_array, 'all') //true
 * -> isNullOrEmpty(empty_elements_array, 'any') //true
 * var some_emnpty_elements_array = ["King", false, "Queen", 533]
 * -> isNullOrEmpty(some_emnpty_elements_array, 'all') //false
 * -> isNullOrEmpty(some_emnpty_elements_array, 'any') //true
 */
export function isNullOrEmpty(params, condition = 'or') {
  let isInvalid = false
  let counter = 0
  if (!params) return true
  if (condition === 'all') {
    condition = 'and'
  } else if (condition === 'any') {
    condition = 'or'
  }
  if (Array.isArray(params)) {
    if (params.length) {
      params.forEach((value, key) => {
        if (
          value === undefined ||
          value === null ||
          value === 0 ||
          value === false ||
          value.length === 0 ||
          value === ''
        ) {
          isInvalid = true
          counter++
        }
      })
    } else {
      isInvalid = true
    }
  } else if (
    params === undefined ||
    params === null ||
    params === '' ||
    params === 0 ||
    params === false ||
    params === []
  ) {
    isInvalid = true
  }
  if (Array.isArray(params) && condition === 'and') {
    if (counter === params.length) {
      return true
    }
    return false
  }
  if (typeof params === 'object') {
    counter = 0
    for (let key in params) {
      if (params[key] && params[key].length !== 0) {
        counter++
      }
    }
    if (condition === 'and') {
      if (counter === 0) {
        return true
      }
      return false
    } else {
      if (counter !== Object.keys(params).length) {
        // atleast one is empty
        return true
      }
      return false
    }
  }
  return isInvalid
}

export function notify(title, message, type, duration = 3000) {
  try {
    message = message || ' '
    notificationStore.addNotification({
      title,
      message,
      type,
      insert: 'bottom',
      container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration,
        onScreen: true,
      },
      showIcon: false,
      pauseOnHover: true,
    })
  } catch (e) {
    console.error(e)
  }
}

const getToastComponent = (type, message) => {
  let component = null
  switch (type) {
    case 'success':
      component = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src="\icons\success.svg" alt="success" />
          <span>{message}</span>
        </div>
      )
      return component
    case 'error':
      component = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src="\icons\error.svg" alt="error" />
          <span>{message}</span>
        </div>
      )
      return component
    default:
      return null
  }
}

export function notifyToast(message, type, ...args) {
  try {
    message = message || ' '
    let component = null
    let background = 'light'

    if (type === 'error') background = 'dark'

    component = getToastComponent(type, message)

    const classname = 'oh-toast' + (type === 'error' ? ' dark' : ' light')

    return (
      <Toast
        open={true}
        type={background}
        toastClassName={classname}
        {...args}
        limit={2}
      >
        {component}
      </Toast>
    )
  } catch (e) {
    console.error(e)
  }
}

export function compareValues(key, ascending = true) {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return !ascending ? comparison * -1 : comparison
  }
}

export function alphabeticalOrder(array, property, ascending) {
  return array.sort(compareValues(property, ascending))
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring)
  const obj = {}
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }

  return obj
}

export const getArray = (array, ...args) => {
  let temp = array
  if (args) {
    for (var i = 0; i < args.length; i++) {
      if (!temp) return []
      if (temp && temp[args[i]]) temp = temp[args[i]]
    }
  }
  return temp
}

export const setPermissions = async () => {
  try {
    const data = await fetchPermissions()
    if (data.success) {
      localStorage.setItem(constants.PERMISSIONS_KEY, data.data)
      localStorage.setItem(
        constants.LAST_UPDATED_PERMISSIONS_DATE,
        dayjs().format('YYYY-MM-DD')
      )
      return data.data
    }
    return null
  } catch (e) {
    console.log('Unable to update permissions', e.stack)
    return null
  }
}

export const generateStyle = (styles) => {
  let cssStyles = ''
  for (const key in styles) {
    cssStyles += `${key}: ${styles[key]};\n`
  }
  return cssStyles
}

export const localStorageActions = {
  set: (key, value) => localStorage.setItem(key, value),
  get: (key) => localStorage.getItem(key),
  remove: (key) => localStorage.removeItem(key),
}
