import { colors } from '@material-ui/core'

import constants from 'app/utils/constants'
import { hexToRgba } from 'app/utils/utils'

import openhousePalette from './colors'

const white = '#FFFFFF'
const black = '#000000'

const dark = {
  type: constants.DARK,
  black,
  white,
  primary: {
    contrastText: white,
    main: openhousePalette['oh-orange'],
    light: '#E5E5E5',
    dark: hexToRgba(colors.grey[900], 0.2),
  },
  secondary: {
    light: colors.grey[600],
    main: '#E5E5E5',
    dark: colors.grey[900],
  },
  background: {
    light: colors.grey[700],
    default: colors.grey[800],
    secondary: hexToRgba(colors.grey[700], 0.2),
  },
  text: {
    primary: white,
  },
  icon: colors.grey[600],
  divider: hexToRgba(colors.grey[900], 0.2),
  action: {
    selected: hexToRgba(colors.grey[900], 0.2),
    hover: colors.grey[700],
  },
}

const light = {
  black,
  white,
  primary: {
    main: openhousePalette['oh-orange'],
    dark: '#282E3C',
  },
  secondary: {
    light: hexToRgba(colors.grey[500], 0.2),
    disabled: hexToRgba('#E5E5E5', 0.5),
    main: '#E5E5E5',
  },
  background: {
    light: '#D7DAE1',
    default: white,
    dark: '#282E3C',
    secondary: '#F3C520',
  },
  text: {
    primary: colors.grey[900],
  },
  icon: '#E5E5E5',
  divider: colors.grey[200],
  action: {
    selected: hexToRgba(colors.grey[200], 0.2),
    hover: colors.grey[200],
  },
}

export const getPalette = (type) => {
  return type === constants.DARK ? dark : light
}
