import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import packageJson from '../package.json'

const { version } = packageJson

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: '',
    release: version,
    environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    attachStacktrace: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}
