import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import ReactNotifications from 'react-notifications-component'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import ErrorBoundary from 'app/components/generic/ErrorBoundary'
import RouteHandler from 'app/routes/RouteHandler'
import { updateTheme } from 'app/store/actions/app'
import { store } from 'app/store/store'
import { loginWithCookie } from 'app/store/thunks/auth'
import { getTheme } from 'app/theme'
import constants from 'app/utils/constants'
import { isUserAuthenticated, refreshAllCookies } from 'app/utils/helpers'

import 'app/App.css'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.compat.css'
import HiddenMathJax from './components/HiddenMathJax'
import { getLoggedInUserPermissions } from './utils/scope/permissions'

function App() {
  const theme = useSelector((state) => state.appReducer.theme)
  const isServiceWorkerUpdated = useSelector(
    (state) => state.appReducer.serviceWorkerUpdated
  )
  const serviceWorkerRegistration = useSelector(
    (state) => state.appReducer.serviceWorkerRegistration
  )

  React.useEffect(() => {
    const handleServiceWorkerUpdate = async () => {
      const updateServiceWorker = async () => {
        const registrationWaiting = serviceWorkerRegistration?.waiting

        if (registrationWaiting) {
          await serviceWorkerRegistration.unregister()

          registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

          refreshAllCookies()
          window.location.reload()

          registrationWaiting.addEventListener('statechange', (e) => {
            if (e.target.state === 'activated') {
              console.log('service worker activated')
            }
          })
        }
      }

      if (process.env.NODE_ENV === 'production' && isServiceWorkerUpdated) {
        console.log('updating service worker')
        await updateServiceWorker()
      }
    }

    handleServiceWorkerUpdate()
  }, [isServiceWorkerUpdated, serviceWorkerRegistration])

  React.useEffect(() => {
    // check theme in localstorage if available
    const savedTheme = localStorage.getItem('theme')

    const {
      appReducer: { theme: currentTheme },
    } = store.getState()

    if (
      savedTheme &&
      [constants.LIGHT, constants.DARK].indexOf(savedTheme) > -1 &&
      savedTheme !== currentTheme
    ) {
      store.dispatch(updateTheme(savedTheme))
    }
  }, [])

  React.useEffect(() => {
    store.dispatch(loginWithCookie())
    ;(async () => {
      if (isUserAuthenticated()) {
        await getLoggedInUserPermissions()
      }
    })()
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider theme={getTheme(theme)}>
        <ErrorBoundary>
          <HiddenMathJax />
          <ReactNotifications />
          <RouteHandler />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
