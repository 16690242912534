/**
 *
 * @param {array/string} permissions
 * @returns boolean
 */
export const userHasPermissions = (userPermissions, permissions) => {
  let localPermissions = permissions
  if (!Array.isArray(permissions)) {
    localPermissions = [permissions]
  }
  return localPermissions.some((permission) =>
    userPermissions.includes(permission)
  )
}
