import { get, patch, post, remove } from 'app/utils/request'

import { apiEndpoints } from './common'

export const fetchPermissions = async () => {
  try {
    const response = await get(
      `${apiEndpoints.permissions}?app_label=question_bank`
    )
    return response.data
  } catch (error) {
    console.log('error in getting permissions :- ', error.message)
    throw error
  }
}
