import { isEmpty } from 'lodash'

import { config } from 'app/pages/QuestionList/components/Pagination/config'
import { makeRequestUrl } from 'app/utils/helpers'
import { get, patch, post, remove } from 'app/utils/request'

import { apiEndpoints } from './common'

export const fetchQuestions = async (search, filters, page, limit) => {
  try {
    const advanceFilters = {}
    for (let field in filters) {
      if (Array.isArray(filters[field])) {
        if (!isEmpty(filters[field])) {
          advanceFilters[field] = filters[field].join(',')
        }
      } else {
        advanceFilters[field] = filters[field]
      }
    }
    const url = makeRequestUrl(apiEndpoints.questions, {
      search,
      limit: limit ? limit : config.ROWS_PER_PAGE,
      offset: config.ROWS_PER_PAGE * (page - 1),
      ...advanceFilters,
    })
    const response = await get(url)
    return response.data
  } catch (error) {
    console.log('error fetching questions :- ', error.message)
    throw error
  }
}

export const fetchChapters = async (limit = 20000) => {
  try {
    const url = makeRequestUrl(apiEndpoints.question_filters, {
      limit,
    })
    const response = await get(url)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

/*gets board,subject,standard uuids*/
export const fetchFiltersForQuestionSet = async () => {
  try {
    const url = makeRequestUrl(apiEndpoints.question_filters_question_set)
    const response = await get(url)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const fetchTopics = async (
  limit = 20000,
  chapter = null,
  filters = {}
) => {
  try {
    let url
    if (chapter) {
      url = `${apiEndpoints.topics}?chapter=${chapter}`
    } else {
      url = apiEndpoints.topics
    }
    const finalUrl = makeRequestUrl(url, {
      limit,
      ...filters,
    })
    const {
      data: { results = [] },
    } = await get(finalUrl)
    return results
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const fetchChaptersForAddModalDropDown = async (
  limit = 20000,
  filters = {}
) => {
  try {
    let url = apiEndpoints.fetch_chapters_for_add_modal_dropdown
    const finalUrl = makeRequestUrl(url, {
      limit,
      ...filters,
    })
    const {
      data: { results = [] },
    } = await get(finalUrl)
    return results
  } catch (error) {
    console.log('error fetching chapters for add modal :- ', error.message)
    throw error
  }
}

export const fetchSubtopicsForAddModalDropDown = async (
  limit = 20000,
  filters = {}
) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.subtopics}`, {
      limit,
      ...filters,
    })
    const {
      data: { results = [] },
    } = await get(url)
    return results
  } catch (error) {
    console.log('error fetching subtopics :- ', error.message)
    throw error
  }
}

export const fetchSubtopics = async (limit, topic) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.subtopics}?topic=${topic}`, {
      limit,
    })
    const {
      data: { results = [] },
    } = await get(url)
    return results
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const fetchQuestion = async (id) => {
  try {
    const url = `${apiEndpoints.questions}/${id}/`
    const { data } = await get(url)
    return data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const postRating = async (ratingData) => {
  try {
    const url = `${apiEndpoints.questionRating}`
    const response = await post(url, ratingData)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const updateRating = async (uuid, ratingData) => {
  try {
    const url = `${apiEndpoints.questionRating}/${uuid}/`
    const response = await patch(url, ratingData)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const postGameRating = async (ratingData) => {
  try {
    const url = `${apiEndpoints.gameRating}`
    const response = await post(url, ratingData)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const updateGameRating = async (uuid, ratingData) => {
  try {
    const url = `${apiEndpoints.gameRating}/${uuid}/`
    const response = await patch(url, ratingData)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

/*gets list of all sets on collections page*/
export const getQuestionSetList = async (search, filters, page) => {
  try {
    const advanceFilters = {}
    for (let field in filters) {
      if (Array.isArray(filters[field])) {
        if (!isEmpty(filters[field])) {
          advanceFilters[field] = filters[field].join(',')
        }
      } else {
        advanceFilters[field] = filters[field]
      }
    }
    const url = makeRequestUrl(apiEndpoints.game_api, {
      search,
      limit: config.ROWS_PER_PAGE,
      offset: config.ROWS_PER_PAGE * (page - 1),
      ...advanceFilters,
    })
    const response = await get(url)
    return response.data
  } catch (error) {
    console.log('error in verify otp :- ', error.message)
    throw error
  }
}

export const deleteRating = async (id) => {
  try {
    const url = `${apiEndpoints.questionRating}/${id}/`
    const response = await remove(url)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const deleteGameRating = async (uuid) => {
  try {
    const url = `${apiEndpoints.gameRating}/${uuid}/`
    const response = await remove(url)
    return response.data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const fetchQuestionSet = async (id) => {
  try {
    const url = `${apiEndpoints.game_api}${id}/`
    const { data } = await get(url)
    return data
  } catch (error) {
    console.log('error fetching chapters :- ', error.message)
    throw error
  }
}

export const fetchSourceList = async () => {
  try {
    const url = `${apiEndpoints.sources}`
    const { data } = await get(url)
    return data?.data
  } catch (error) {
    console.log('error fetching sources :- ', error.message)
    throw error
  }
}
