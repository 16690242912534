import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'

import App from 'app/App'
import {
  initializeServiceWorker,
  updateServiceWorker,
} from 'app/store/actions/app'
import { store } from 'app/store/store'
// import { store, persistor } from 'app/store/store'

import './index.css'
import * as serviceWorker from './serviceWorker'

// configure sentry
import './sentry'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <App />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// registering service worker for faster load (https://github.com/facebook/create-react-app/issues/5359)
const serviceWorkerConfig = {
  onSuccess: () => store.dispatch(initializeServiceWorker()),
  onUpdate: (registration) => store.dispatch(updateServiceWorker(registration)),
}

serviceWorker.register(serviceWorkerConfig)
