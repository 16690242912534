import _ from 'lodash'

import * as types from '../types/questions'

export const initialState = {
  allQuestions: {
    loading: true,
    isLoaded: false,
    data: [],
    totalCount: 1,
  },
  modalFormData: {
    board: null,
    standard: null,
    subject: null,
    chapter: null,

    question_type: null,
    difficulty_level: [],
    topics: [],
    chapters: [],
    subTopics: [],
    count: 5,
    questionSetTitle: '',
    columns: '',
    rows: '',
  },
  allChapters: {
    loading: true,
    isLoaded: false,
    data: [],
  },
  filters: {
    board: [],
    standard: [],
    subject: [],
    time_to_solve: [],
    question_type: [],
    difficulty_level: [],
    type: '',
  },
  board: [],
  class: [],
  subjects: [],
  chapters: [],
  search: '',
  board_uuid: [],
  class_uuid: [],
  subjects_uuid: [],
  question_set: [],
  selected_question_index: null,
  question_set_list: null,
  question_replace_list: null,
  gameSet: {
    name: '',
    type: '',
    rating: 0,
    time_to_solve: 0,
    difficulty_level: ['easy', 'medium', 'hard'],
    question_type: [],
    question_sets: [],
    shared_with: [],
    is_public: false,
  },
  view: 'questions',
  notificationToast: null,
}

export const setChapters = (state, payload) => {
  return {
    ...state,
    isLoaded: true,
    allChapters: {
      loading: false,
      isLoaded: true,
      data: payload,
    },
    board: payload.board.sort(),
    class: payload.standards,
    subjects: payload.subjects,
    chapters: payload.chapters,
  }
}

const customSort = (a, b) =>
  parseInt(a.label.substring(6)) - parseInt(b.label.substring(6))

export const setFiltersForQuestionSet = (state, payload) => {
  //storing board_uuid, class_uuid and subjects_uuid in localStorage
  localStorage.setItem(
    'board_uuid',
    JSON.stringify(
      Object.keys(payload?.boards).map((value) => {
        return {
          label: payload?.boards[value],
          value,
        }
      })
    )
  )
  localStorage.setItem(
    'class_uuid',
    JSON.stringify(
      Object.keys(payload?.standards)
        .map((value) => {
          return {
            label: payload?.standards[value],
            value,
          }
        })
        .sort(customSort)
    )
  )
  localStorage.setItem(
    'subjects_uuid',
    JSON.stringify(
      Object.keys(payload?.subjects).map((value) => {
        return {
          label: payload?.subjects[value],
          value,
        }
      })
    )
  )
  return {
    ...state,
    board_uuid: Object.keys(payload?.boards).map((value) => {
      return {
        label: payload?.boards[value],
        value,
      }
    }),
    class_uuid: Object.keys(payload?.standards)
      .map((value) => {
        return {
          label: payload?.standards[value],
          value,
        }
      })
      .sort(customSort),
    subjects_uuid: Object.keys(payload?.subjects).map((value) => {
      return {
        label: payload?.subjects[value],
        value,
      }
    }),
  }
}

export const setRandomQuestionSet = (
  state,
  payload,
  categoryIndex,
  isEntireSet
) => {
  if (
    payload.length <= state.modalFormData.count &&
    payload.length != 0 &&
    categoryIndex == undefined
  ) {
    payload.push(
      ...Array(state.modalFormData.count - payload.length).map(() => {})
    )
  } else {
    payload.push(
      ...Array(state.modalFormData.rows - payload.length).map(() => {})
    )
  }

  if (isEntireSet && categoryIndex !== undefined) {
    let isQuestionsAvailable = false
    let question_set = JSON.parse(JSON.stringify(state.gameSet.question_sets))
    question_set.map((value, index) => {
      if (payload[index].length !== 0) {
        value.questions = payload[index]
        isQuestionsAvailable = true
      }
    })
    if (isQuestionsAvailable) {
      return {
        ...state,
        gameSet: {
          ...state.gameSet,
          question_sets: question_set,
        },
      }
    } else {
      return {
        ...state,
      }
    }
  } else if (isEntireSet && categoryIndex === undefined) {
    if (!payload[0]?.length)
      return {
        ...state,
      }
    else {
      const numRows = payload[0]?.filter((item) => item).length
      if (numRows > 0)
        return {
          ...state,
          question_set: payload[0],
          modalFormData: {
            ...state.modalFormData,
            count: numRows,
          },
        }
      else {
        return {
          ...state,
          question_set: payload[0],
        }
      }
    }
  } else if (categoryIndex === undefined) {
    const numRows = payload[0]?.filter((item) => item).length
    if (numRows > 0)
      return {
        ...state,
        question_set: payload[0],
        modalFormData: {
          ...state.modalFormData,
          count: numRows,
        },
      }
    else {
      return {
        ...state,
        question_set: payload[0],
      }
    }
  } else {
    if (!payload[0]?.length)
      return {
        ...state,
      }
    else {
      let question_set = state.gameSet.question_sets
      if (question_set[categoryIndex])
        question_set[categoryIndex].questions = payload[0]
      return {
        ...state,
        gameSet: {
          ...state.gameSet,
          question_sets: question_set,
        },
      }
    }
  }
}

export const resetRandomQuestionSet = (state, payload) => {
  if (state.gameSet.type === 'jeopardy' && payload >= 0) {
    let question_set = state.gameSet.question_sets
    if (question_set[payload]) question_set[payload].questions = []
    return {
      ...state,
      gameSet: {
        ...state.gameSet,
        question_sets: question_set,
      },
    }
  }
  return {
    ...state,
    question_set: [],
  }
}

export const setRandomQuestion = (state, payload, categoryIndex) => {
  if (payload[0]) {
    if (categoryIndex !== undefined) {
      const question_set_temp = state.gameSet.question_sets
      question_set_temp[categoryIndex].questions.splice(
        state.selected_question_index,
        1,
        payload[0][0]
      )
      return {
        ...state,
        gameSet: {
          ...state.gameSet,
          question_sets: question_set_temp,
        },
      }
    } else {
      const copyData = state.question_set.slice()
      copyData.splice(state.selected_question_index, 1, payload[0][0])
      return {
        ...state,
        question_set: copyData,
      }
    }
  }
}

export const setBoard = (state, payload) => ({
  ...state,
  board: payload,
})

export const setSearch = (state, payload) => ({
  ...state,
  search: payload,
})

const updateFilter = (state, field, value) => ({
  ...state,
  filters: {
    ...state.filters,
    [field]: value,
  },
})

const questionsReducer = (state = initialState, action) => {
  let data
  switch (action.type) {
    case types.UPDATE_QUESTION_MODAL_FORM_DATA_SUCCESS:
      return {
        ...state,
        modalFormData: {
          ...state.modalFormData,
          ...action.data,
        },
      }

    case types.UPDATE_GAME_SET_DATA_SUCCESS:
      return {
        ...state,
        gameSet: {
          ...state.gameSet,
          ...action.data,
        },
      }

    case types.FETCH_QUESTIONS:
      return {
        ...state,
        allQuestions: {
          ...state.allQuestions,
          loading: true,
          isLoaded: false,
        },
      }

    case types.FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        allQuestions: {
          loading: false,
          isLoaded: true,
          data: action.data,
          totalCount: action.data.count,
        },
      }

    case types.FETCH_QUESTIONS_FAILED:
      return {
        ...state,
        allQuestions: {
          loading: false,
          isLoaded: true,
          data: [],
        },
      }

    case types.UPDATE_QUESTION_SUCCESS:
      if (state.allQuestions?.data?.results) {
        data = {
          ...state.allQuestions.data,
          results: state.allQuestions.data.results.map((question) => {
            if (question.id === action.questionId) {
              return action.data
            } else {
              return question
            }
          }),
        }
      } else {
        data = state.allQuestions.data
      }
      return {
        ...state,
        allQuestions: {
          ...state.allQuestions,
          data,
        },
      }

    case types.UPDATE_GAME_LIST_DATA_SUCCESS:
      let results
      if (state.question_set_list.results) {
        results = state.question_set_list.results.map((questionSet) => {
          if (questionSet.uuid === action.uuid) {
            return action.data
          }
          return questionSet
        })
      } else {
        results = state.question_set_list.results
      }
      return {
        ...state,
        question_set_list: {
          ...state.question_set_list,
          results,
        },
      }

    case types.FETCH_QUESTION_REPLACE_LIST:
      return {
        ...state,
        question_replace_list: {
          ...state.question_replace_list,
        },
      }

    case types.FETCH_QUESTION_REPLACE_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        question_replace_list: {
          data: action.data,
          totalCount: action.data.count,
        },
      }

    case types.FETCH_CHAPTERS:
      return {
        ...state,
        allChapters: {
          ...state.allChapters,
          loading: true,
          isLoaded: false,
        },
      }

    case types.FETCH_CHAPTERS_SUCCESS:
      return setChapters(state, action.data)

    case types.FETCH_FILTERS_FOR_GAME_SUCCESS:
      return setFiltersForQuestionSet(state, action.data)

    case types.FETCH_RANDOM_GAME_SUCCESS:
      return setRandomQuestionSet(
        state,
        action.data,
        action.categoryIndex,
        action.isEntireSet,
        action.setNotificationToastForRandomisation
      )

    case types.FETCH_RANDOM_QUESTION_SUCCESS:
      return setRandomQuestion(state, action.data, action.categoryIndex)

    case types.SET_SELECTED_QUESTION_INDEX:
      return {
        ...state,
        selected_question_index: action.index,
      }

    case types.FETCH_RANDOM_GAME_FAILURE:
      return resetRandomQuestionSet(state, action.categoryIndex)

    case types.SET_BOARD:
      return setBoard(state, action.data)

    case types.SET_SEARCH_TEXT:
      return setSearch(state, action.data)

    case types.UPDATE_FILTER_SUCCESS:
      return updateFilter(state, action.field, action.value)

    case types.GET_GAME_LIST_SUCCESS:
      return {
        ...state,
        question_set_list: action.data,
      }

    case types.SET_HOME_PAGE_VIEW:
      return {
        ...state,
        view: action.view,
      }
    case types.SET_NOTIFY_TOAST:
      return {
        ...state,
        notificationToast: action.toast,
      }

    default:
      return state
  }
}

export { questionsReducer }
