import { dispatchSetNotifyToast } from 'app/store/dispatcher/questions'
import { makeRequestUrl, notify, notifyToast } from 'app/utils/helpers'
import { get, patch, post, remove } from 'app/utils/request'

import { apiEndpoints } from './common'

export const postQuestion = async (saveData) => {
  try {
    const url = makeRequestUrl(apiEndpoints.questions)
    const { data } = await post(url, saveData)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const updateQuestion = async (saveData, id) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.questions}/${id}/`)
    const { data } = await patch(url, saveData)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const deleteQuestion = async (id) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.questions}/${id}/`)
    const { data } = await remove(url)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

/*generates a set of 5 random questions*/
export const generateQuestionSet = async (
  queries,
  setNotificationToastForRandomisation,
  isEntireSet
) => {
  try {
    const url = makeRequestUrl(apiEndpoints.random_questions)
    const { data } = await post(url, queries)
    let isQuestionsAvailable = false
    let count = 0
    let successMessage = 'Questions have been randomized'
    if (isEntireSet) {
      let question_set = data
      question_set.map((value) => {
        if (value?.length !== 0) {
          count++
          isQuestionsAvailable = true
        }
      })
    }

    if (isQuestionsAvailable && count !== queries.question_sets?.length)
      successMessage = `${count} category questions have been randomized`

    !isQuestionsAvailable || (!data[0].length && !isEntireSet)
      ? setNotificationToastForRandomisation(
          'No more questions to randomize',
          'error'
        )
      : setNotificationToastForRandomisation(successMessage, 'success')

    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

/*get single random question*/
export const getRandomQuestion = async (
  queries,
  setNotificationToastForRandomisation
) => {
  try {
    const url = makeRequestUrl(apiEndpoints.random_questions)
    const { data } = await post(url, queries)
    !data.length
      ? setNotificationToastForRandomisation(
          'No more questions to randomize',
          'error'
        )
      : setNotificationToastForRandomisation(
          'The question has been randomized',
          'success'
        )
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const postQuestionSet = async (saveData) => {
  try {
    const url = makeRequestUrl(apiEndpoints.game_api)
    const { data } = await post(url, saveData)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const updateQuestionSet = async (uuid, saveData) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.game_api}${uuid}/`)
    const { data } = await patch(url, saveData)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const shareQuestionSet = async (saveData) => {
  try {
    const url = makeRequestUrl(apiEndpoints.game_share)
    const { data } = await post(url, saveData)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const fetchTeachersShared = async (id) => {
  try {
    const url = `${apiEndpoints.game_share}?game=${id}`
    const { data } = await get(url)
    return data
  } catch (error) {
    console.log('error fetching teacher shared :- ', error.message)
    throw error
  }
}

export const deleteTeacherShared = async (id) => {
  try {
    const url = `${apiEndpoints.game_share}/${id}/`
    const { data } = await remove(url)
    return data
  } catch (error) {
    console.log('error deleting teacher shared :- ', error.message)
    throw error
  }
}

export const deleteQuestionSet = async (id) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.game_api}${id}/`)
    const { data } = await remove(url)
    return data
  } catch (error) {
    // logger.error(error.message)
  }
}

export const downloadCsvFile = async (id, title) => {
  try {
    const url = makeRequestUrl(`${apiEndpoints.game_api}${id}/xlsx/`)
    const { data } = await get(url, { responseType: 'blob' })
    var urli = window.URL.createObjectURL(data)
    var a = document.createElement('a')
    a.href = urli
    a.download = `${title}.xlsx`
    document.body.appendChild(a)
    a.click()
    a.remove()
  } catch (error) {
    // logger.error(error.message)
  }
}
