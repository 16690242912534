import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PrivateRoute from 'app/components/PrivateRoute'
import MainLayout from 'app/layouts/MainLayout'
import RoutePages from 'app/routes/RoutePages'
import {
  navigationMenus,
  publicNavigationPaths,
  mainLayoutNavigationPaths,
} from 'app/utils/config'
import constants from 'app/utils/constants'
import { isUserAuthenticated } from 'app/utils/helpers'
import { userHasPermissions } from 'app/utils/scope'

const RouteHandler = () => {
  const buildMainRoutes = React.useCallback((menu) => {
    const userIsAuthenticated = isUserAuthenticated()
    let userPermissions
    if (userIsAuthenticated) {
      const permissions = localStorage.getItem(constants.PERMISSIONS_KEY)
      if (Array.isArray(permissions)) {
        userPermissions = permissions
      } else {
        userPermissions = permissions ? permissions.split(',') : []
      }
    }

    return menu
      .filter((nav) =>
        userIsAuthenticated && nav.requiredPermission && userPermissions
          ? userHasPermissions(userPermissions, nav.requiredPermission)
          : true
      )
      .map((item) => {
        if (item.subMenus) {
          return buildMainRoutes(item.subMenus)
        } else if (item.path) {
          return (
            <PrivateRoute
              exact
              key={item.path}
              path={item.path}
              component={RoutePages[item.component || null]}
            />
          )
        }

        return null
      })
  }, [])

  const mainRoutes = React.useMemo(() => {
    return buildMainRoutes(navigationMenus)
  }, [buildMainRoutes])

  const [publicNavigationPathsArray, mainLayoutNavigationPathsArray] =
    React.useMemo(() => {
      return [
        Object.values(publicNavigationPaths),
        Object.values(mainLayoutNavigationPaths),
      ]
    }, [])

  return (
    <Switch>
      <React.Suspense fallback={null}>
        <Route exact path={publicNavigationPathsArray}>
          {/* <Route exact path="/edit">
            <Redirect to={mainLayoutNavigationPaths.edit} />
          </Route> */}

          <React.Suspense fallback={null}>
            <Route
              exact
              path={publicNavigationPaths.login}
              component={RoutePages['LoginPage']}
            />
          </React.Suspense>
        </Route>

        <Route exact path={mainLayoutNavigationPathsArray}>
          <MainLayout>
            <Switch>
              <React.Suspense fallback={null}>{mainRoutes}</React.Suspense>
            </Switch>
          </MainLayout>
        </Route>
        {/* <Route exact path="" component={RoutePages.NotFoundPage} /> */}
      </React.Suspense>
    </Switch>
  )
}

export default RouteHandler
