import { isEmpty } from 'lodash'

import { post } from '../app/utils/request'

const mandatoryEnvironments = ['REACT_APP_API_BASE_URL']

mandatoryEnvironments.forEach((environment) => {
  if (!process.env[environment]) {
    console.error(`${environment} is not set in environment`)
  }
})

export const apiURLOrigin = process.env.REACT_APP_API_BASE_URL

export const apiEndpoints = {
  auth: `${apiURLOrigin}/auth`,
  permissions: `${apiURLOrigin}/base/permissions`,
  addTribe: `${apiURLOrigin}/oh_clubs/add_tribe_member`,
  batch: `${apiURLOrigin}/oh_classes/batch`,
  fetchBatch: `${apiURLOrigin}/oh_classes/batch/alfred`,
  board: `${apiURLOrigin}/commons/board`,
  chapter: `${apiURLOrigin}/commons/chapter`,
  centre: `${apiURLOrigin}/commons/centre`,
  course: `${apiURLOrigin}/oh_classes/course`,
  cloudFileStorage: 'https://api.cloudinary.com/v1_1',
  club: `${apiURLOrigin}/oh_clubs/club`,
  enrolment: `${apiURLOrigin}/oh_classes/enrolment`,
  fetchEnrolment: `${apiURLOrigin}/oh_classes/enrolment/alfred`,
  event: `${apiURLOrigin}/oh_events`,
  growth: `${apiURLOrigin}/oh_growth`,
  ohClasses: `${apiURLOrigin}/oh_classes`,
  payment: `${apiURLOrigin}/oh_payments`,
  paymentInvoice: `${apiURLOrigin}/oh_payments/invoice/alfred`,
  referralCode: `${apiURLOrigin}/oh_clubs/referral_code`,
  room: `${apiURLOrigin}/commons/room`,
  oh_schedules: `${apiURLOrigin}/oh_schedules`,
  schedule: `${apiURLOrigin}/oh_schedules/schedule`,
  standard: `${apiURLOrigin}/commons/standard`,
  school: `${apiURLOrigin}/commons/school`,
  subject: `${apiURLOrigin}/commons/subject`,
  subjectCategory: `${apiURLOrigin}/commons/subject_category`,
  thirdparty: `${apiURLOrigin}/thirdparty`,
  tribe: `${apiURLOrigin}/oh_clubs/tribe`,
  user: `${apiURLOrigin}/oh_users`,
  journeyMentor: `${apiURLOrigin}/oh_journey_mentors`,
  common: `${apiURLOrigin}`,
  communication: `${apiURLOrigin}/oh_communications/communication`,
  createSibling: `${apiURLOrigin}/base/user`,
  student: `${apiURLOrigin}/oh_users/student`,
  createStudent: `${apiURLOrigin}/oh_users/create_student`,
  getStudentParent: `${apiURLOrigin}/oh_users/get_student_parent`,
  waitlist: `${apiURLOrigin}/oh_clubs/waitlist`,
  website: `${apiURLOrigin}/oh_www`,
  mockTest: `${apiURLOrigin}/oh_mock_tests`,
  questions: `${apiURLOrigin}/oh_question_bank/question`,
  game_share: `${apiURLOrigin}/oh_question_bank/game-share`,
  questionRating: `${apiURLOrigin}/oh_question_bank/question-rating`,
  gameRating: `${apiURLOrigin}/oh_question_bank/game-rating`,
  topics: `${apiURLOrigin}/oh_question_bank/topic`,
  subtopics: `${apiURLOrigin}/oh_question_bank/subtopic`,
  sources: `${apiURLOrigin}/oh_question_bank/question/sources`,
  upload_url: `${apiURLOrigin}/utils/get_upload_url`,
  question_filters: `${apiURLOrigin}/oh_question_bank/commons`,
  random_questions: `${apiURLOrigin}/oh_question_bank/get-random-questions`,
  question_filters_question_set: `${apiURLOrigin}/oh_question_bank/commons-uuid`,
  game_api: `${apiURLOrigin}/oh_question_bank/game/`,
  fetch_chapters_for_add_modal_dropdown: `${apiURLOrigin}/commons/chapter`,
}

export const validateUpdateRequest = (data) => {
  if (isEmpty(data.uuid) && isEmpty(data.id)) {
    throw new Error('uuid/id is missing / invalid')
  }
}

export const validateDeleteRequest = (ids) => {
  if (!(Array.isArray(ids) && !isEmpty(ids))) {
    throw new Error('ids are missing / invalid')
  }
}

export const generateOtp = async (data) => {
  try {
    const response = await post(`${apiEndpoints.auth}/generate_otp/`, {
      phone_number: data.phone_number,
    })

    return response.data
  } catch (error) {
    console.log('error in generate otp :- ', error.message)
    throw error
  }
}
