import { notify, notifyToast } from 'app/utils/helpers'

import { dispatchSetNotifyToast } from '../dispatcher/questions'
import * as types from '../types/auth'

const initialState = {
  accessToken: null,
  refreshToken: null,
  error: null,
  user: {},
  otpSent: false,
  isLoggedIn: false,
  sendingOtp: false,
  loggingIn: false,
  loginProcessStart: false,
  notificationToast: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_OTP:
      return {
        ...state,
        sendingOtp: true,
      }
    case types.SEND_OTP_SUCCESS:
      return {
        ...state,
        user: action.user,
        isStudent: action.user && action.user.is_student,
        uuid: action.user && action.user.uuid,
        otpSent: true,
        isLoggedIn: false,
        sendingOtp: false,
        notificationToast: notifyToast('OTP sent successfully', 'success'),
      }
    case types.SEND_OTP_FAILED:
      return {
        ...state,
        error: action.error,
        otpSent: true,
        isLoggedIn: false,
        sendingOtp: false,
        notificationToast: notifyToast(`Oops! ${action.error}`, 'error'),
      }
    case types.LOGIN_START:
      return {
        ...state,
        loggingIn: true,
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.data.access_token,
        refreshToken: action.data.refresh_token,
        isLoggedIn: action.data.isValidUser,
        loggingIn: false,
      }
    case types.LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        loggingIn: false,
        notificationToast: notifyToast(`Oops! ${action.error}`, 'error'),
      }
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
      }
    case types.LOGIN_WITH_COOKIE:
      return {
        ...state,
        loginProcessStart: true,
      }
    case types.LOGIN_WITH_COOKIE_SUCCESS:
      return {
        ...state,
        error: action.error,
        isLoggedIn: true,
        accessToken: action.data.access_token,
        refreshToken: action.data.refresh_token,
        loginProcessStart: false,
      }
    case types.LOGIN_WITH_COOKIE_FAILED:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        loginProcessStart: false,
      }

    default:
      return state
  }
}

export { authReducer }
