import axios from 'axios'

import { apiEndpoints } from './common'

const sendOtp = async (data) => {
  try {
    const response = await axios.post(`${apiEndpoints.auth}/send_otp/`, {
      phone_number: data.phone_number,
      ...(Object.prototype.hasOwnProperty.call(data, 'suppress') && {
        suppress: data.suppress,
      }),
    })

    return response.data
  } catch (error) {
    console.log('error in sending otp :- ', error.message)
    throw error
  }
}

const verifyOtp = async (data) => {
  try {
    const response = await axios.post(`${apiEndpoints.auth}/verify_otp/`, {
      phone_number: data.phone_number,
      otp: data.otp,
    })

    return response.data
  } catch (error) {
    console.log('error in verify otp :- ', error.message)
    throw error
  }
}

const getNewAccessToken = async (data) => {
  try {
    const response = await axios.post(`${apiEndpoints.auth}/refresh_token/`, {
      refresh_token: data.refresh_token,
    })

    return response.data
  } catch (error) {
    console.log('error in getting new access token :- ', error.message)
    throw error
  }
}

export { sendOtp, verifyOtp, getNewAccessToken }
