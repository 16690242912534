import Dayjs from 'dayjs'
import { store as notificationStore } from 'react-notifications-component'

import { sendOtp as sendOtpApi, verifyOtp } from 'api'
import constants from 'app/utils/constants'
import {
  getNewAccessToken,
  notify,
  notifyToast,
  setCookie,
} from 'app/utils/helpers'
import * as Roles from 'app/utils/scope/roles'
import { decodeJWT } from 'app/utils/utils'

import {
  loggingIn,
  loginFailed,
  loginSuccess,
  loginWithCookieFailed,
  loginWithCookieStart,
  loginWithCookieSuccess,
  logoutSuccess,
  sendOtpFailed,
  sendOtpSuccess,
  sendingOtp,
} from '../actions/auth'

export function loginWithCookie() {
  return (dispatch) => {
    dispatch(loginWithCookieStart())
    return getNewAccessToken()
      .then((data) => {
        if (data && data.access_token !== '') {
          dispatch(loginWithCookieSuccess(data))
        } else {
          dispatch(loginWithCookieFailed('Unable to verify user'))
        }
        return data
      })
      .catch((error) => {
        dispatch(loginWithCookieFailed('Unable to verify user'))
        return error
      })
  }
}

export function sendOtp(phone_number) {
  return (dispatch) => {
    dispatch(sendingOtp())
    return sendOtpApi({ phone_number })
      .then((data) => {
        notificationStore.removeNotification(1)
        dispatch(sendOtpSuccess(data.user))
        return data
      })
      .catch((error) => {
        dispatch(sendOtpFailed(constants.ERROR_MESSAGE))
        return error
      })
  }
}

export function login(phone_number, otp) {
  return (dispatch) => {
    dispatch(loggingIn())
    return verifyOtp({ phone_number, otp })
      .then((data) => {
        if (data.access_token) {
          let user = decodeJWT(data.access_token)
          let isValidUser = false
          Roles.ALL.forEach((role) => {
            if (user.realm_access?.roles?.includes(role)) {
              isValidUser = true
            }
          })
          console.log('isvaliduser', isValidUser)
          if (!isValidUser) {
            isValidUser = false
            notify(
              'Permission Denied',
              "You don't have permission to access CMS currently",
              'danger'
            )
          } else {
            setCookie(constants.ACCESS_TOKEN_KEY, data.access_token)
            setCookie(constants.REFRESH_TOKEN_KEY, data.refresh_token)
            setCookie(
              constants.REFRESH_TOKEN_LAST_UPDATED,
              new Dayjs().format('YYYY/MM/DD HH:mm:ss')
            )
          }
          data.isValidUser = isValidUser
          dispatch(loginSuccess(data))
        } else {
          let _error = data.error_description || constants.ERROR_MESSAGE
          dispatch(loginFailed(_error))
        }
        return data
      })
      .catch((error) => {
        dispatch(loginFailed(constants.ERROR_MESSAGE))
        return error
      })
  }
}

export function logout() {
  return (dispatch) => {
    setCookie(constants.ACCESS_TOKEN_KEY, '')
    setCookie(constants.REFRESH_TOKEN_KEY, '')
    setCookie(constants.REFRESH_TOKEN_LAST_UPDATED, '')
    localStorage.removeItem(constants.PERMISSIONS_KEY)
    localStorage.removeItem(constants.LAST_UPDATED_PERMISSIONS_DATE)
    dispatch(logoutSuccess())
  }
}
