import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { navigationPaths } from 'app/utils/config'
import { isUserAuthenticated } from 'app/utils/helpers'

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={({ location, ...matchProps }) => {
        return isUserAuthenticated() ? (
          <Component {...matchProps} />
        ) : (
          <Redirect
            to={{
              pathname: navigationPaths.login,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
}

export default PrivateRoute
