import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

// import { persistStore, persistReducer } from 'redux-persist'

// import storage from 'redux-persist/lib/storage'

import { appReducer } from './reducers/app'
import { authReducer } from './reducers/auth'
import { questionsReducer } from './reducers/questions'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [thunkMiddleware, sagaMiddleware]

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  questions: questionsReducer,
})

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)

// finally run saga
sagaMiddleware.run(rootSaga)

// const persistor = persistStore(store)

// export { store, persistor }
export { store }
