import * as types from '../types/auth'

export function sendingOtp() {
  return {
    type: types.SEND_OTP,
  }
}

export function sendOtpSuccess(user) {
  return {
    type: types.SEND_OTP_SUCCESS,
    user,
  }
}

export function sendOtpFailed(error) {
  return {
    type: types.SEND_OTP_FAILED,
    error,
  }
}

export function loggingIn() {
  return {
    type: types.LOGIN_START,
  }
}

export function loginSuccess(data) {
  return {
    type: types.LOGIN_SUCCESS,
    data,
  }
}

export function loginFailed(error) {
  return {
    type: types.LOGIN_FAILED,
    error,
  }
}

export function logoutStart() {
  return {
    type: types.LOGOUT_START,
  }
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
  }
}

export function loginWithCookieStart() {
  return {
    type: types.LOGIN_WITH_COOKIE,
  }
}

export function loginWithCookieSuccess(data) {
  return {
    type: types.LOGIN_WITH_COOKIE_SUCCESS,
    data,
  }
}

export function loginWithCookieFailed(error) {
  return {
    type: types.LOGIN_WITH_COOKIE_FAILED,
    error,
  }
}
