import * as types from '../types/questions'

export function updateQuestionModalFormDataSuccess(data) {
  return {
    type: types.UPDATE_QUESTION_MODAL_FORM_DATA_SUCCESS,
    data,
  }
}

export function updateGameSetDataSuccess(data) {
  return {
    type: types.UPDATE_GAME_SET_DATA_SUCCESS,
    data,
  }
}

export function updateGameListDataSuccess(uuid, data) {
  return {
    type: types.UPDATE_GAME_LIST_DATA_SUCCESS,
    uuid,
    data,
  }
}

export function fetchQuestionsReplace(search, filters) {
  return {
    type: types.FETCH_QUESTION_REPLACE_LIST,
    search,
    filters,
  }
}

export function fetchQuestionReplaceListSuccess(data) {
  return {
    type: types.FETCH_QUESTION_REPLACE_LIST_SUCCESS,
    data,
  }
}

export function fetchQuestions(search, filters, page) {
  return {
    type: types.FETCH_QUESTIONS,
    search,
    filters,
    page,
  }
}

export function fetchQuestionsSuccess(data) {
  return {
    type: types.FETCH_QUESTIONS_SUCCESS,
    data,
  }
}

export function fetchQuestionsFailed(error) {
  return {
    type: types.FETCH_QUESTIONS_FAILED,
    error,
  }
}

export function fetchChapters(limit) {
  return {
    type: types.FETCH_CHAPTERS,
    limit,
  }
}

export function fetchChaptersSuccess(data) {
  return {
    type: types.FETCH_CHAPTERS_SUCCESS,
    data,
  }
}

export function fetchChaptersFailed(error) {
  return {
    type: types.FETCH_CHAPTERS_FAILED,
    error,
  }
}

export function fetchFiltersForQuestionSet() {
  return {
    type: types.FETCH_FILTERS_FOR_GAME,
  }
}

export function fetchFiltersForQuestionSetSuccess(data) {
  return {
    type: types.FETCH_FILTERS_FOR_GAME_SUCCESS,
    data,
  }
}

export function fetchRandomQuestionSet(
  queries,
  categoryIndex,
  setNotificationToastForRandomisation,
  isEntireSet
) {
  return {
    type: types.FETCH_RANDOM_GAME,
    queries,
    categoryIndex,
    setNotificationToastForRandomisation,
    isEntireSet,
  }
}

export function fetchRandomQuestionSetSuccess(
  data,
  categoryIndex,
  isEntireSet,
  setNotificationToastForRandomisation
) {
  return {
    type: types.FETCH_RANDOM_GAME_SUCCESS,
    data,
    categoryIndex,
    isEntireSet,
    setNotificationToastForRandomisation,
  }
}

export function fetchRandomQuestionSetFailure(categoryIndex) {
  return {
    type: types.FETCH_RANDOM_GAME_FAILURE,
    categoryIndex,
  }
}

export function setSearchTextSuccess(data) {
  return {
    type: types.SET_SEARCH_TEXT,
    data,
  }
}

export function updateFilterSuccess(field, value) {
  return {
    type: types.UPDATE_FILTER_SUCCESS,
    field,
    value,
  }
}

export function updateQuestionSuccess(questionId, data) {
  return {
    type: types.UPDATE_QUESTION_SUCCESS,
    questionId,
    data,
  }
}

/*action to get one random questions*/
export function fetchRandomQuestion(
  queries,
  categoryIndex,
  setNotificationToastForRandomisation
) {
  return {
    type: types.FETCH_RANDOM_QUESTION,
    queries,
    categoryIndex,
    setNotificationToastForRandomisation,
  }
}

export function fetchRandomQuestionSuccess(data, categoryIndex) {
  return {
    type: types.FETCH_RANDOM_QUESTION_SUCCESS,
    data,
    categoryIndex,
  }
}

export function setSelectedQuestionIndex(index) {
  return {
    type: types.SET_SELECTED_QUESTION_INDEX,
    index,
  }
}

/*action to get five random questions*/
export function getQuestionSetList(search, filters, page) {
  return {
    type: types.GET_GAME_LIST,
    search,
    filters,
    page,
  }
}

export function getQuestionSetListSuccess(data) {
  return {
    type: types.GET_GAME_LIST_SUCCESS,
    data,
  }
}

export function setHomePageView(view) {
  return {
    type: types.SET_HOME_PAGE_VIEW,
    view,
  }
}

export function setNotifyToast(toast) {
  return {
    type: types.SET_NOTIFY_TOAST,
    toast,
  }
}
