import React from 'react'

const LoginPage = React.lazy(() => import('app/pages/LoginPage'))

const EditPage = React.lazy(() => import('app/pages/EditQuestion'))
const QuestionsPage = React.lazy(() => import('app/pages/QuestionList'))
const CreateQuizPage = React.lazy(() => import('app/pages/CreateQuizPage'))
const CreateJeopardyPage = React.lazy(() =>
  import('app/pages/CreateJeopardyPage')
)
const CollectionsPage = React.lazy(() => import('app/pages/QuestionSetList'))

const NotFoundPage = React.lazy(() => import('app/pages/NotFoundPage'))

const RoutePages = {
  LoginPage,
  EditPage,
  QuestionsPage,
  NotFoundPage,
  CreateQuizPage,
  CreateJeopardyPage,
  CollectionsPage,
}

export default RoutePages
