import axios from 'axios'

import { apiURLOrigin } from 'api/common'
import constants from 'app/utils/constants'
import {
  getAccessToken,
  makeRequestUrl,
  notify,
  notifyToast,
} from 'app/utils/helpers'
import { checkIfQueryPresentInURL } from 'app/utils/utils'

// creating instance for later use in all requests
const axiosInstance = axios.create()

// setting request interceptor, to feed mandatory headers in all requests
axiosInstance.interceptors.request.use(
  async (options) => {
    try {
      const { url, method } = options

      if (
        method === 'get' &&
        url.startsWith(apiURLOrigin) &&
        !checkIfQueryPresentInURL(url, 'search') &&
        !checkIfQueryPresentInURL(url, 'limit')
      ) {
        // get page limit from localstorage, fallbacking to 25 if null
        const numberOfRows = localStorage.getItem(constants.ROWS_PER_PAGE) || 25

        options.url = makeRequestUrl(options.url, { limit: numberOfRows })
      }

      const headers = {
        ...options.headers,
      }

      if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
        headers['Content-Type'] = 'application/json'
      }

      if (!Object.prototype.hasOwnProperty.call(headers, 'Authorization')) {
        const accessToken = getAccessToken()
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`
        }
      }

      options.headers = headers
      return options
    } catch (error) {
      console.log('error in getting token :- ', error)
    }
  },
  (error) => {
    console.log('error in request handling :- ', error)
    return Promise.reject(error)
  }
)

// setting response interceptor, mainly for logging errors and response validation
axiosInstance.interceptors.response.use(
  (response) => {
    // validate create, update and delete method response
    const {
      config: { method, url },
    } = response

    if (
      url.startsWith(apiURLOrigin) &&
      ['post', 'patch', 'put', 'delete'].indexOf(method) > -1
    ) {
      // mutation request, check with known backend parameter, i.e success: true
      if (
        !Object.prototype.hasOwnProperty.call(response.data, 'success') ||
        response.data.success
      ) {
        // its a valid response
        return response
      } else {
        // got something unexpected from server even if response status is proper
        return Promise.reject(
          new Error(response.data.error || 'Something Went Wrong !')
        )
      }
    }

    return response
  },
  (error) => {
    if (error.response) {
      // some server side error
      console.log('error in server response :- ', error.toJSON())
      if (error.response.status === 500) {
        notify('error', constants.ERROR_MESSAGE, 'danger')
      } else if (error.response.status >= 400 && error.response.status < 500) {
        let msg = error.response.data.error || error.response.data.errors
        if (Array.isArray(msg)) {
          msg = msg.join(', ')
        }
        // got something unexpected from server even if response status is proper
        return Promise.reject(new Error(msg || 'Something Went Wrong !'))
      }
    } else if (error.request) {
      // request initiated but no response from server, most probably bcz of network error
      console.log('no response received :- ', error)
      notify('error', constants.ERROR_MESSAGE, 'danger')
    } else {
      console.log(
        'Error occured because of something unexpected :- ',
        error.message
      )
      notify('error', constants.ERROR_MESSAGE, 'danger')
    }

    return Promise.reject(error)
  }
)

const get = async (url, options = {}) => {
  try {
    const response = await axiosInstance.get(url, options)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

const post = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.post(url, data, options)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

const put = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.put(url, data, options)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

const patch = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.patch(url, data, options)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

const remove = async (url, options = {}) => {
  try {
    const response = await axiosInstance.delete(url, options)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

export { get, post, put, patch, remove, axiosInstance }
