// import { envTypes } from 'constants/environment'

import {
  ADD_GAME,
  CHANGE_QUESTION,
  VIEW_GAME,
  VIEW_QUESTION,
} from './scope/permissions'

const {
  REACT_APP_NAME,
  REACT_APP_VERSION,
  REACT_APP_CUSTOM_NODE_ENV,
  REACT_APP_OH_API_URL,
} = process.env

// const assetsEnv = (REACT_APP_CUSTOM_NODE_ENV === envTypes.DEVELOPMENT
//   ? envTypes.STAGING
//   : REACT_APP_CUSTOM_NODE_ENV
// ).toLocaleLowerCase()

export default {
  app: {
    name: REACT_APP_NAME,
    version: REACT_APP_VERSION,
  },
  environment: REACT_APP_CUSTOM_NODE_ENV,
  ohApiUrl: REACT_APP_OH_API_URL,
}

export const publicNavigationPaths = {
  login: '/login',
}

export const mainLayoutNavigationPaths = {
  edit: '/edit',
  home: '/',
  createQuiz: '/create-quiz',
  createJeopardy: '/create-jeopardy',
  collections: '/collections',
}

export const additionalNavigationPaths = {
  // scheduler: '/scheduler'
}

export const navigationPaths = {
  ...publicNavigationPaths,
  ...mainLayoutNavigationPaths,
  ...additionalNavigationPaths,
}

export const drawerWidth = 270

export const navigationMenus = [
  {
    name: 'edit',
    displayName: 'Edit Question',
    path: navigationPaths.edit,
    requiredPermission: CHANGE_QUESTION,
    component: 'EditPage',
    icon: {
      name: 'People',
    },
  },
  {
    name: 'home',
    displayName: 'Questions Page',
    path: navigationPaths.home,
    requiredPermission: VIEW_QUESTION,
    component: 'QuestionsPage',
    icon: {
      name: 'People',
    },
  },
  {
    name: 'collections',
    displayName: 'Collection Page',
    path: navigationPaths.collections,
    requiredPermission: VIEW_GAME,
    component: 'CollectionsPage',
    icon: {
      name: 'People',
    },
  },
  {
    name: 'notFound',
    displayName: 'Not Found',
    path: navigationPaths.notFound,
    component: 'NotFound',
    icon: {
      name: 'People',
    },
  },
  {
    name: 'CreateQuiz',
    displayName: 'Create Quiz',
    path: navigationPaths.createQuiz,
    requiredPermission: ADD_GAME,
    component: 'CreateQuizPage',
    icon: {
      name: 'People',
    },
  },
  {
    name: 'CreateJeopardy',
    displayName: 'Create Jeopardy',
    path: navigationPaths.createJeopardy,
    requiredPermission: ADD_GAME,
    component: 'CreateJeopardyPage',
    icon: {
      name: 'People',
    },
  },
]
