import Dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import { parse } from 'papaparse'
import sanitizeHTML from 'sanitize-html'

export const formatedDate = (date) => {
  try {
    return new Date(date).toISOString().split('T')[0]
  } catch (error) {
    console.log('invalid input :- ', error)
  }
}

export const decodeJWT = (token) => {
  try {
    return jwtDecode(token)
  } catch (error) {
    console.log('invalid input :- ', error)
  }
}

export const isTokenExpired = (accessToken) => {
  try {
    const decodedToken = decodeJWT(accessToken)
    return Date.now() >= decodedToken?.exp * 1000
  } catch (error) {
    console.log('invalid input :- ', error)
  }
}

export const checkIfQueryPresentInURL = (url, query) => {
  const regex = `[?&]${query}=`
  return new RegExp(regex).test(url)
}

export const makeQueryStringFromObject = (queryObject) => {
  try {
    let queryString = ''

    for (const [key, value] of Object.entries(queryObject)) {
      queryString += `${key}=${
        Array.isArray(value) ? JSON.stringify(value) : value
      }&`
    }

    return queryString.slice(0, -1)
  } catch (error) {
    console.log('error in makeQueryStringFromObject :- ', error)
  }
}

export const getGroupedResult = (input, property) => {
  const result = flow(groupBy(property))(input)

  return result
}

export const deletePropertyFromObject = (key, { [key]: _, ...newObject }) => {
  return newObject
}

export const csvToJson = (file, config = {}) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      ...config,
      complete: (result) => {
        resolve(result.data)
      },
      error: (err) => {
        reject(err)
      },
    })
  })
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min)
}

export const hexToRgba = (hex, a) => {
  try {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? `rgba(${parseInt(result[1], 16)}, ${parseInt(
          result[2],
          16
        )}, ${parseInt(result[3], 16)}, ${a})`
      : null
  } catch (error) {
    console.log('error in hexRgba :- ', error)
  }
}

export const getCurrentMonth = () => {
  return new Dayjs().month() + 1
}

// https://stackoverflow.com/a/2901298
export const numberWithCommas = (x) => {
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') // Invalid regular expression: invalid group specifier name issue in safari
  return x.toLocaleString()
}

export const formatAmount = (text) => {
  if (text !== null && text !== undefined) {
    return `${String.fromCharCode(8377)} ${numberWithCommas(text)}`
  }

  return text
}

export const sanitize = (htmlString) => {
  try {
    return sanitizeHTML(htmlString)
  } catch (error) {
    console.log('error in sanitizing html :- ', sanitize)
  }
}
