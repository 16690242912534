import PropTypes from 'prop-types'
import React from 'react'

const MainLayout = (props) => {
  return <div>{props.children}</div>
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
