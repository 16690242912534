import * as types from '../types/app'

const initialState = {
  drawerNavigationMenuState: {},
  theme: 'light',
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_DRAWER_NAVIGATION_MENU_STATE:
      return {
        ...state,
        drawerNavigationMenuState: action.value,
      }

    case types.UPDATE_THEME:
      return {
        ...state,
        theme: action.value,
      }

    case types.INITIALIZE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      }

    case types.UPDATE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      }

    default:
      return state
  }
}

export { appReducer }
