import dayjs from 'dayjs'

import constants from '../constants'
import { isNullOrEmpty, setPermissions } from '../helpers'

export const ADD_QUESTION = 'add_question'
export const CHANGE_QUESTION = 'change_question'
export const VIEW_QUESTION = 'view_question'
export const DELETE_QUESTION = 'delete_question'

export const ADD_QUESTION_ORDER = 'add_questionorder'
export const CHANGE_QUESTION_ORDER = 'change_questionorder'
export const VIEW_QUESTION_ORDER = 'view_questionorder'
export const DELETE_QUESTION_ORDER = 'delete_questionorder'

export const ADD_OPTION = 'add_option'
export const CHANGE_OPTION = 'change_option'
export const VIEW_OPTION = 'view_option'
export const DELETE_OPTION = 'delete_option'

export const ADD_QUESTION_RATING = 'add_questionrating'
export const CHANGE_QUESTION_RATING = 'change_questionrating'
export const VIEW_QUESTION_RATING = 'view_questionrating'
export const DELETE_QUESTION_RATING = 'delete_questionrating'

export const ADD_GAME = 'add_game'
export const CHANGE_GAME = 'change_game'
export const VIEW_GAME = 'view_game'
export const DELETE_GAME = 'delete_game'

export const ADD_GAME_RATING = 'add_gamerating'
export const CHANGE_GAME_RATING = 'change_gamerating'
export const VIEW_GAME_RATING = 'view_gamerating'
export const DELETE_GAME_RATING = 'delete_gamerating'

export const ADD_GAME_SHARE = 'add_gameshare'
export const CHANGE_GAME_SHARE = 'change_gameshare'
export const VIEW_GAME_SHARE = 'view_gameshare'
export const DELETE_GAME_SHARE = 'delete_gameshare'

export const ADD_TOPIC = 'add_topic'
export const CHANGE_TOPIC = 'change_topic'
export const VIEW_TOPIC = 'view_topic'
export const DELETE_TOPIC = 'delete_topic'

export const ADD_SUB_TOPIC = 'add_subtopic'
export const CHANGE_SUB_TOPIC = 'change_subtopic'
export const VIEW_SUB_TOPIC = 'view_subtopic'
export const DELETE_SUB_TOPIC = 'delete_subtopic'
/**
 * Update and return latest permissions
 * @returns {array}
 */
export const getLoggedInUserPermissions = async () => {
  let permissions
  if (localStorage.getItem(constants.LAST_UPDATED_PERMISSIONS_DATE)) {
    const lastUpdatedOn = dayjs(
      localStorage.getItem(constants.LAST_UPDATED_PERMISSIONS_DATE),
      'YYYY-MM-DD'
    )
    if (dayjs().diff(lastUpdatedOn, 'days') > 1) {
      permissions = await setPermissions()
    } else {
      permissions = localStorage.getItem(constants.PERMISSIONS_KEY)
    }
  } else {
    permissions = await setPermissions()
  }
  if (permissions) {
    if (Array.isArray(permissions)) {
      return permissions
    } else {
      return permissions?.split(',')
    }
  } else {
    return []
  }
}
