import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'

import { fetchQuestions, fetchChapters } from 'api'
import { generateQuestionSet, getRandomQuestion } from 'api/postData'
import { fetchFiltersForQuestionSet, getQuestionSetList } from 'api/questions'

import {
  fetchQuestionsSuccess,
  getQuestionSetListSuccess,
  fetchChaptersSuccess,
  fetchFiltersForQuestionSetSuccess,
  fetchRandomQuestionSetSuccess,
  fetchRandomQuestionSetFailure,
  fetchRandomQuestionSuccess,
  fetchQuestionReplaceListSuccess,
} from '../actions/questions'
import * as types from '../types/questions'

export function* handleFetchQuestions({ search, filters, page }) {
  try {
    const res = yield call(fetchQuestions, search, filters, page)
    yield put(fetchQuestionsSuccess(res))
  } catch (error) {
    console.log(error)
  }
}

export function* watchHandleFetchQuestions() {
  yield takeLatest(types.FETCH_QUESTIONS, handleFetchQuestions)
}

export function* handleFetchChapters({ limit }) {
  try {
    const res = yield call(fetchChapters, limit)
    yield put(fetchChaptersSuccess(res))
  } catch (error) {
    console.log(error)
  }
}
export function* handleFetchFiltersForQuestionSet() {
  try {
    const res = yield call(fetchFiltersForQuestionSet)
    yield put(fetchFiltersForQuestionSetSuccess(res))
  } catch (error) {
    console.log(error)
  }
}

export function* handleFetchRandomQuestionSet({
  queries,
  categoryIndex,
  setNotificationToastForRandomisation,
  isEntireSet,
}) {
  try {
    const res = yield call(
      generateQuestionSet,
      queries,
      setNotificationToastForRandomisation,
      isEntireSet
    )
    res
      ? yield put(
          fetchRandomQuestionSetSuccess(
            res,
            categoryIndex,
            isEntireSet,
            setNotificationToastForRandomisation
          )
        )
      : yield put(fetchRandomQuestionSetFailure(categoryIndex))
  } catch (error) {
    console.log(error)
  }
}

export function* handleFetchRandomQuestion({
  queries,
  categoryIndex,
  setNotificationToastForRandomisation,
}) {
  try {
    const res = yield call(
      getRandomQuestion,
      queries,
      setNotificationToastForRandomisation
    )
    yield put(fetchRandomQuestionSuccess(res, categoryIndex))
  } catch (error) {
    console.log(error)
  }
}

export function* handleFetchQuestionReplaceList({ search, filters }) {
  try {
    const res = yield call(fetchQuestions, search, filters, 1, 100)
    yield put(fetchQuestionReplaceListSuccess(res))
  } catch (error) {
    console.log(error)
  }
}

export function* watchHandleFetchQuestionReplaceList() {
  yield takeLatest(
    types.FETCH_QUESTION_REPLACE_LIST,
    handleFetchQuestionReplaceList
  )
}

export function* handleFetchQuestionSetList({ search, filters, page }) {
  try {
    const res = yield call(getQuestionSetList, search, filters, page)
    yield put(getQuestionSetListSuccess(res))
  } catch (error) {
    console.log(error)
  }
}

export function* watchHandleFetchChapters() {
  yield takeLatest(types.FETCH_CHAPTERS, handleFetchChapters)
}

export function* watchHandleFetchFiltersForQuestionSet() {
  yield takeLatest(
    types.FETCH_FILTERS_FOR_GAME,
    handleFetchFiltersForQuestionSet
  )
}

export function* watchHandleFetchRandomQuestionSet() {
  yield takeEvery(types.FETCH_RANDOM_GAME, handleFetchRandomQuestionSet)
}

export function* watchHandleFetchRandomQuestion() {
  yield takeLatest(types.FETCH_RANDOM_QUESTION, handleFetchRandomQuestion)
}

export function* watchHandleFetchQuestionSetList() {
  yield takeLatest(types.GET_GAME_LIST, handleFetchQuestionSetList)
}
