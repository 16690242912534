import {
  fetchChapters,
  fetchFiltersForQuestionSet,
  fetchQuestions,
  updateQuestionModalFormDataSuccess,
  setSearchTextSuccess,
  updateFilterSuccess,
  updateQuestionSuccess,
  fetchRandomQuestionSet,
  fetchRandomQuestion,
  setSelectedQuestionIndex,
  getQuestionSetList,
  setHomePageView,
  updateGameSetDataSuccess,
  setNotifyToast,
  updateGameListDataSuccess,
  fetchQuestionsReplace,
} from 'app/store/actions/questions'
import { store } from 'app/store/store'

export const updateModalFormData = (data) => {
  store.dispatch(updateQuestionModalFormDataSuccess(data))
}
export const updateGameSetData = (data) => {
  store.dispatch(updateGameSetDataSuccess(data))
}
export const updateGameListData = (uuid, data) => {
  store.dispatch(updateGameListDataSuccess(uuid, data))
}
export const fetchQuestionReplaceList = (search, filters) => {
  store.dispatch(fetchQuestionsReplace(search, filters))
}
export const fetchAllQuestions = (search, filters, page = 1) => {
  store.dispatch(fetchQuestions(search, filters, page))
}
export const fetchAllChapters = (limit = 20000) => {
  store.dispatch(fetchChapters(limit))
}
export const fetchAllFiltersForQuestionSet = () => {
  store.dispatch(fetchFiltersForQuestionSet())
}
export const fetchAllRandomQuestionSet = (
  queries,
  categoryIndex,
  setNotificationToastForRandomisation,
  isEntireSet
) => {
  store.dispatch(
    fetchRandomQuestionSet(
      queries,
      categoryIndex,
      setNotificationToastForRandomisation,
      isEntireSet
    )
  )
}
export const setSearchText = (data) => {
  store.dispatch(setSearchTextSuccess(data))
}
export const updateFilter = (field, value) => {
  store.dispatch(updateFilterSuccess(field, value))
}
export const updateQuestion = (questionId, data) => {
  store.dispatch(updateQuestionSuccess(questionId, data))
}
export const fetchSingleRandomQuestion = (
  queries,
  categoryIndex,
  setNotificationToastForRandomisation
) => {
  store.dispatch(
    fetchRandomQuestion(
      queries,
      categoryIndex,
      setNotificationToastForRandomisation
    )
  )
}
export const setQuestionIndex = (index) => {
  store.dispatch(setSelectedQuestionIndex(index))
}
export const fetchQuestionSetList = (search, filters, page = 1) => {
  store.dispatch(getQuestionSetList(search, filters, page))
}
export const setHomePageViewDispatcher = (view) => {
  store.dispatch(setHomePageView(view))
}
export const dispatchSetNotifyToast = (toast) => {
  store.dispatch(setNotifyToast(toast))
}
