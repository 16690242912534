import { all, fork } from 'redux-saga/effects'

import { watchHandleLogout } from './auth'
import {
  watchHandleFetchQuestions,
  watchHandleFetchChapters,
  watchHandleFetchFiltersForQuestionSet,
  watchHandleFetchRandomQuestionSet,
  watchHandleFetchRandomQuestion,
  watchHandleFetchQuestionSetList,
  watchHandleFetchQuestionReplaceList,
} from './questions'

const sagas = [
  fork(watchHandleLogout),
  fork(watchHandleFetchQuestions),
  fork(watchHandleFetchChapters),
  fork(watchHandleFetchFiltersForQuestionSet),
  fork(watchHandleFetchRandomQuestionSet),
  fork(watchHandleFetchRandomQuestion),
  fork(watchHandleFetchQuestionSetList),
  fork(watchHandleFetchQuestionReplaceList),
]

export default function* rootSaga() {
  yield all(sagas)
}
